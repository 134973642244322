@font-face {
  font-family: "Harmony";
  src: local("Harmony"),
    url("./fonts/Harmony.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "BritishCastilla";
  src: local("BritishCastilla"),
    url("./fonts/BritishCastilla.otf") format("opentype");
  font-weight: normal;
}

@import "~react-image-gallery/styles/css/image-gallery.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body { 
  background: #798979;
}

.App {
  background: #798979;
  height: 100vh;
}